import deployment from './deployment.json';

export const xummConfig = {
    name: 'niftyx',
    env: 'production',
    AppId: 'fd0d3948-b36c-4184-9a25-28358e9f1857',
    AppSecret: '7e748266-4edc-46dd-8a40-45d9f5afe5b2',
};

export const appConfig = () => {
    const config = {
        prd: {
            name: 'niftyx',
            env: 'production',
            mintPrice: 1.0,
        },
        dev: {
            name: 'niftyx',
            env: 'development',
            mintPrice: 0.85,
        },
        local: {
            name: 'niftyx',
            env: 'development',
            mintPrice: 0.65,
        }
    };
    return config[deployment.env];
};

export const apiConfig = () => {
    const config = {
        prd: {
            apiBaseUrl: 'https://api.niftyx.net/v1',
            webBaseUrl: 'https://niftyx.net',
            wsBaseUrl: 'wss://ws.niftyx.net',
            pinataGateway: 'https://peach-genetic-iguana-803.mypinata.cloud/ipfs',
        },
        dev: {
            apiBaseUrl: 'http://localhost:3000/v1',
            webBaseUrl: 'http://localhost:3001',
            wsBaseUrl: 'ws://localhost:3002',
            pinataGateway: 'https://peach-genetic-iguana-803.mypinata.cloud/ipfs',
        },
        local: {
            apiBaseUrl: 'http://localhost:5000',
            webBaseUrl: 'http://localhost:1234',
            wsBaseUrl: 'ws://localhost:5002',
            pinataGateway: 'https://peach-genetic-iguana-803.mypinata.cloud/ipfs',
        },
    };
    return config[deployment.env];
};